import STYLES from "./EnumVXButton";

const COLOR_TYPE_DEFAULT              = 'default';
const COLOR_TYPE_DEFAULT_INVERT       = 'default-invert';
const COLOR_TYPE_CALL_TO_ACTION       = 'c2a';
const COLOR_TYPE_INFO                 = 'info';
const COLOR_TYPE_SUCCESS              = 'success';
const COLOR_TYPE_VIDEOCALL            = 'videocall';
const COLOR_TYPE_DISABLED             = 'disabled';
const COLOR_TYPE_OPTION               = 'option';
const COLOR_TYPE_FOOTER               = 'footer';
const COLOR_TYPE_TRANSPARENT          = 'transparent';
const COLOR_TYPE_TRANSPARENT_BORDERED = 'transparent-bordered';
const COLOR_TYPE_HIGHLIGHT            = 'highlight';
const COLOR_TYPE_SPECIAL              = 'special';
const COLOR_TYPE_SPECIAL_INVERT       = 'special-invert';
const COLOR_TYPE_HIGHLIGHT_GOLD       = 'highlight-gold';
const COLOR_TYPE_HIGHLIGHT_DARK_GOLD  = 'highlight-dark-gold';
const COLOR_TYPE_GOLD                 = 'gold';
const GOLD_TYPE_GRADIENT              = 'gold-gradient';
const COLOR_TYPE_FAILURE              = 'failure';
const COLOR_TYPE_MOOD_3               = 'mood-3';
const COLOR_TYPE_MOOD_9			   	  = 'mood-9';
const COLOR_BLACK                     = 'black';

/**
 * @param {string} colorType
 * @return {string}
 */
function getColor(colorType) {
	switch (colorType) {
		case COLOR_TYPE_CALL_TO_ACTION:
			return '-btn-color-special';

		case COLOR_TYPE_INFO:
			return '-btn-color-option';

		case COLOR_TYPE_SUCCESS:
			return '-btn-color-success';

		case COLOR_TYPE_VIDEOCALL:
			return '-btn-color-videocall';

		case COLOR_TYPE_DISABLED:
			return '-btn-color-disabled';

		case COLOR_TYPE_OPTION:
			return '-btn-color-option';

		case COLOR_TYPE_FOOTER:
			return '-btn-color-footer';

		case COLOR_TYPE_TRANSPARENT:
			return '-btn-color-transparent';

		case COLOR_TYPE_HIGHLIGHT:
			return '-btn-color-highlight';

		case COLOR_TYPE_SPECIAL:
			return '-btn-color-special';

		case COLOR_TYPE_SPECIAL_INVERT:
			return '-btn-color-special-invert';

		case COLOR_TYPE_HIGHLIGHT_GOLD:
			return '-btn-color-highlight-gold';

		case COLOR_TYPE_HIGHLIGHT_DARK_GOLD:
			return '-btn-color-highlight-dark-gold';

		case COLOR_TYPE_GOLD:
			return '-btn-color-gold';

		case GOLD_TYPE_GRADIENT:
			return '-btn-color-gold-gradient';

		case COLOR_TYPE_FAILURE:
			return '-btn-color-failure';

		case COLOR_TYPE_TRANSPARENT_BORDERED:
			return '-btn-color-transparent-bordered';

		case COLOR_TYPE_DEFAULT_INVERT:
			return '-btn-color-default-invert';

		case COLOR_BLACK:
			return '-btn-color-black';

		case COLOR_TYPE_MOOD_3:
			return '-btn-color-mood-3';

		case COLOR_TYPE_MOOD_9:
			return '-btn-color-mood-9';

		case COLOR_TYPE_DEFAULT:
		default:
			return '-btn-color-default';
	}
}

function getBorder(type) {
	switch (type) {
		case STYLES.BORDER.OUTLINE:
			return '-btn-border-outline';

		default:
		case STYLES.BORDER.NONE:
			return '';
	}
}

function getTextClassByColor(color) {
	switch (color) {
		case STYLES.COLORS.WHITE:
			return 'h-color-fg';

		case STYLES.COLORS.MOOD1:
			return 'h-color-mood-1';

		case STYLES.COLORS.MOOD2:
			return 'h-color-mood-2';

		case STYLES.COLORS.MOOD3:
			return 'h-color-mood-3';

		case STYLES.COLORS.MOOD4:
			return 'h-color-mood-4';

		case STYLES.COLORS.MOOD5:
			return 'h-color-mood-5';

		case STYLES.COLORS.MOOD6:
			return 'h-color-mood-6';

		default:
			return '';
	}
}

/**
 *
 * @param {VXButtonLineStyle} style
 */
function getClassByLineStyle(style) {
	let className = '';
	if (!style) {
		return className;
	}

	if (style.bold) {
		className += ' h-text-bold';
	} else if (style.light) {
		className += ' h-text-light';
	}

	if (style.italic) {
		className += ' h-text-italic';
	}

	if (style.underline) {
		className += ' h-text-underline';
	}

	if (style.lineThrough) {
		className += ' h-text-linethrough';
	}

	if (style.upperCase) {
		className += ' h-text-uppercase';
	}

	if (style.color) {
		className += ' ' + getTextClassByColor(style.color);
	}

	return className;
}

function whichAnimationEvent() {
	let t;
	const el = document.createElement("fakeelement");

	const animations = {
		"animation":       "animationend",
		"OAnimation":      "oAnimationEnd",
		"MozAnimation":    "animationend",
		"WebkitAnimation": "webkitAnimationEnd",
	};

	for (t in animations) {
		if (el.style[t] !== undefined) {
			return animations[t];
		}
	}
}

export {
	getColor,
	getBorder,
	whichAnimationEvent,
	getTextClassByColor,
	getClassByLineStyle,
	COLOR_TYPE_DEFAULT,
	COLOR_TYPE_CALL_TO_ACTION,
	COLOR_TYPE_DISABLED,
	COLOR_TYPE_FOOTER,
	COLOR_TYPE_HIGHLIGHT,
	COLOR_TYPE_INFO,
	COLOR_TYPE_OPTION,
	COLOR_TYPE_SPECIAL,
	COLOR_TYPE_SPECIAL_INVERT,
	COLOR_TYPE_SUCCESS,
	COLOR_TYPE_VIDEOCALL,
	COLOR_TYPE_TRANSPARENT,
	COLOR_TYPE_HIGHLIGHT_GOLD,
	COLOR_TYPE_HIGHLIGHT_DARK_GOLD,
	COLOR_TYPE_GOLD,
	GOLD_TYPE_GRADIENT,
	COLOR_TYPE_FAILURE,
	COLOR_TYPE_TRANSPARENT_BORDERED,
	COLOR_TYPE_DEFAULT_INVERT,
	COLOR_TYPE_MOOD_3,
	COLOR_TYPE_MOOD_9,
    COLOR_BLACK
};
