const LEFT  = 'left';
const RIGHT = 'right';

const STYLES = {
	BOLD:        1,
	LIGHT:       2,
	ITALIC:      3,
	UNDERLINE:   4,
	LINETHROUGH: 5,
	UPPERCASE:   6,

	COLORS: {
		WHITE:		 'fg',
		MOOD1:		 'm1',
		MOOD2:		 'm2',
		MOOD3:		 'm3',
		MOOD4:		 'm4',
		MOOD5:		 'm5',
		MOOD6:		 'm6',
		DEFAULT:     'default',
		GOLD:		 'gold',
		PRIMARY:	 'primary',
		SPECIAL:	 'special',
		SUCCESS:	 'success',
		TRANSPARENT: 'transparent',
	},

	BORDER: {
		NONE:    0,
		OUTLINE: 1,
	},

	SIZE: {
		TINY:   'tiny',
		SMALL:  'small',
		MEDIUM: 'medium',
		BIG:    'big',
		FULL:   'full',

		W20:    'w20',
		W25:    'w25',
		W26:    'w26',
		W100:   'w100',

		PX450: '450px',
		PX117: '117px',
		PX157: '157px',
		PX220: '220px',
	},

	PRESET: {
		OUTLINE: 'outline',
		DEFAULT: 'default',
	},
	
	ICON: {
		EYE:	  'icon -icon-eyes-full',
		LIVECHAT: 'icon -icon-webcam-single-full',
		MESSAGE:  'icon -icon-chat-flat',
		PHONE:	  'icon -icon-phone-full',
		VIP:  	  'icon-actor -icon-person-princess',
		VOYEUR:   'icon -icon-voyeur-full',
	},

	ICON_POSITION: {
		LEFT,
		RIGHT,
	},
	ICON_POSITIONS: [LEFT, RIGHT],
};

export default STYLES;