'use strict';

import STYLES from "./EnumVXButton";

/**
 *
 * @constructor
 */
function VXButtonConfig() {

	/**
	 *
	 * @type {string}
	 */
	this.color = '';

	/**
	 *
	 * @type {function}
	 */
	this.onClick = null;

	/**
	 *
	 * @type {[VXButtonLineConfig]}
	 */
	this.line1 = [];

	/**
	 *
	 * @type {[VXButtonLineConfig]}
	 */
	this.line2 = [];

	/**
	 *
	 * @type {string}
	 */
	this.icon = '';

	/**
	 *
	 * @type {boolean}
	 */
	this.disabled = false;

}

/**
 *
 * @param {string} text
 * @param {VXButtonLineStyle} style
 * @constructor
 */
function VXButtonLineConfig(text, style = null) {

	/**
	 *
	 * @type {string}
	 */
	this.text = text;

	/**
	 *
	 * @type {VXButtonLineStyle}
	 */
	this.style = style ? style : new VXButtonLineStyle();

}

/**
 *
 * @param {array} apply
 * @constructor
 */
function VXButtonLineStyle(apply = []) {

	/**
	 *
	 * @type {boolean}
	 */
	this.bold = false;

	/**
	 *
	 * @type {boolean}
	 */
	this.light = false;

	/**
	 *
	 * @type {boolean}
	 */
	this.italic = false;

	/**
	 *
	 * @type {boolean}
	 */
	this.underline = false;

	/**
	 *
	 * @type {boolean}
	 */
	this.lineThrough = false;

	/**
	 *
	 * @type {boolean}
	 */
	this.upperCase = false;

	/**
	 *
	 * @type {?string}
	 */
	this.color = null;

	for (let i = 0; i < apply.length; i++) {
		switch (apply[i]) {
			case STYLES.BOLD:
				this.bold = true;
				break;
			case STYLES.LIGHT:
				this.light = true;
				break;
			case STYLES.ITALIC:
				this.italic = true;
				break;
			case STYLES.UNDERLINE:
				this.underline = true;
				break;
			case STYLES.LINETHROUGH:
				this.lineThrough = true;
				break;
			case STYLES.UPPERCASE:
				this.upperCase = true;
				break;
			default:
				for (const prop in STYLES.COLORS) {
					if (STYLES.COLORS[prop] === apply[i]) {
						this.color = apply[i];
						break;
					}
				}
		}
	}

}


export {
	VXButtonConfig,
	VXButtonLineConfig,
	VXButtonLineStyle,
};